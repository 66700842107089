import React, { Component } from 'react';
import emailjs from '@emailjs/browser';

class ContactForm extends Component {

  constructor() {
    super();
    this.state = { email: '', name: '', subject: '', message: '', email_err: '', name_err: '', subject_err: '', message_err: '', return_msg: '', flag: false }

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
    var EmailReg = /(\w+)\s(\w+)/;
    if (e.target.value === '')
      this.setState({ email_err: 'Required Field' });
    else if (EmailReg.test(e.target.value))
      this.setState({ email_err: '' });
    else
      this.setState({ email_err: 'Enter Valid Email' });

  }

  handleChangeName(e) {
    this.setState({ name: e.target.value });
    if (e.target.value === '')
      this.setState({ name_err: 'Required Field' });
    else
      this.setState({ name_err: '' });
  }
  handleChangeSubject(e) {
    this.setState({ subject: e.target.value });
    if (e.target.value === '')
      this.setState({ subject_err: 'Required Field' });
    else
      this.setState({ subject_err: '' });
  }
  handleChangeMessage(e) {
    this.setState({ message: e.target.value });
    if (e.target.message === '')
      this.setState({ message_err: 'Required Field' });
    else
      this.setState({ message_err: '' });
  }

  handleSubmit1() {
    if (this.state.name === '')
      this.setState({ name_err: 'Required Field' });
    if (this.state.email === '')
      this.setState({ email_err: 'Required Field' });
    if (this.state.subject === '')
      this.setState({ subject_err: 'Required Field' });
    if (this.state.message === '')
      this.setState({ message_err: 'Required Field' });

    if (this.state.name === '' || this.state.email === '' || this.state.subject === '' || this.state.message === '') {
      this.setState({ return_msg: 'Fill All First', flag: true });
    }
    else {
      this.setState({ return_msg: 'Success.', flag: true });
    }


    var templateParams = {
      email: this.state.email,
      name: this.state.name,
      notes: this.state.message,
      phone: this.state.subject
    };


    emailjs.send('service_3hf8qs8', 'template_n0ndqdg', templateParams, 'm3zm_jqN_xN4KA0XD')
      .then(function (response) {
        console.log('SUCCESS!', response.status, response.text);
      }, function (error) {
        console.log('FAILED...', error);
      });


  }
  render() {

    return (

      <div className="contact-form">
        <div id="message">
          {this.state.flag ?
            <div className="alert alert-success">
              <strong>{this.state.return_msg}</strong>
            </div>
            : null}
        </div>
        <form method="post" id="contactForm">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="input-group">
                <input type="text" id="name" value={this.state.name} onChange={this.handleChangeName} className="form-control" placeholder="Full Name:" />
                <span id="err">{this.state.name_err}</span>
              </div>
              <div className="input-group">
                <input type="text" className="form-control" value={this.state.email} onChange={this.handleChangeEmail} id="email" placeholder="Email:" />
                <span id="err">{this.state.email_err}</span>
              </div>
              <div className="input-group">
                <input type="text" id="tel" value={this.state.subject} onChange={this.handleChangeSubject} className="form-control" placeholder="Number:" />
                <span id="err">{this.state.subject_err}</span>
              </div>
            </div>
            <div className="form-group col-md-6 col-sm-12">
              <div className="input-group input_group_full_width">
                <textarea name="comments" id="message" value={this.state.message} onChange={this.handleChangeMessage} rows="6" className="form-control" placeholder="Message"></textarea>
                <span id="err">{this.state.message_err}</span>
              </div>
            </div>
            <div className="col-sm-12">
              <button type="button" name="send" onClick={this.handleSubmit1} className="submit-button btn btn-chos" value="Submit">Submit</button>
              <div id="simple-msg"></div>
            </div>

          </div>
        </form>
      </div>

    )

  }
}

//service_133yxgl -> service id

export default ContactForm;